import React from 'react';

const Footer = () => {
    return (
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 px-3 mt-4 border-top bg-light">
            <p className="col-md-4 mb-0 text-body-secondary">Copyright © 2024 TupeGPT</p>

          <ul className="nav col-md-4 justify-content-end text-body-secondary">
            <li className="nav-item">
              <span style={{cursor: 'default'}}
                    className="fst-italic text-secondary align-items-center justify-content-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                  Stand with Gaza &#127477;&#127480;
              </span>
            </li>
            </ul>
        </footer>
    );
};

export default Footer;
