import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import {useLocalStorage} from "./LocalStorage";

const useApi = () => {
    const { user, setUser } = useUser();
    const [localStorageUser, setLocalStorageUser] = useLocalStorage('user', null);

    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_BACKEND_DOMAIN}/api`,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    instance.interceptors.request.use((config) => {
        const token = user?.access;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    instance.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = user?.refresh;
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/refresh-token/`, { refresh: refreshToken });

                const newAccessToken = response.data.access;

                setUser({
                    access: newAccessToken,
                    refresh: refreshToken,
                    email: localStorageUser?.email || response.data?.display || '',
                });

                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return instance(originalRequest);
            } catch (refreshError) {
                setUser(null);
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    });

    return instance;
};

export default useApi;
