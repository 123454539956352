export const initializeGoogleSignIn = ({handleGoogleCredentialResponse}) => {
  window.google && window.google.accounts.id.initialize({
    client_id: "698728715588-ruuuvotd67ojkblpvdloba0t6gt906id.apps.googleusercontent.com",
    callback: (response) => handleGoogleCredentialResponse({response}),
  });

  window.google && window.google.accounts.id.renderButton(
    document.getElementById("googleSignInButton"),
    {
      theme: "outline",
      size: "large",
    }
  );
};
