import React from 'react';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import Header from "../components/Header";

function HomePage() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <div className="text-center mt-5">
          <h2>Welcome to TubeGPT</h2>
          <p>Ask questions about YouTube videos.</p>
          <p className="text-secondary">Start by going to <Link to="/chat">Chat</Link> tab</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
