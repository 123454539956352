import React, { createContext, useContext } from 'react';
import {useLocalStorage} from "../utils/LocalStorage";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage('user', null);

    const customSetUser = (_user) => {
        // TODO: Notify server to invalidate credentials
        setUser(_user);
    }

    return (
        <UserContext.Provider value={{ user, setUser: customSetUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};
