import React from 'react';
import Footer from "./Footer";
import Header from "./Header";

const TermsOfUse = () => {
    return (
      <div className="">
        <Header/>
        <div className="w-75 mx-auto d-flex flex-column justify-content-center" style={{maxWidth: '50rem'}}>
          <h1 className="text-center my-4">Terms of Use</h1>
          <p><strong>Effective Date:</strong> OCTOBER 19, 2024</p>

          <h3>1. Acceptance of Terms</h3>
          <p>Welcome to TubeGPT. By accessing or using our website or services, you agree to be bound by these Terms of Use (“Terms”). If you do not agree with any part of these Terms, please do not use our services.</p>

          <h3>2. Description of Services</h3>
          <p>TubeGPT allows users to submit YouTube URLs, ask questions about these videos, store messages, and sign in via Google. We reserve the right to modify, suspend, or discontinue any part of our services at any time, with or without notice.</p>

          <h3>3. User Responsibilities</h3>
          <p>As a user of TubeGPT, you agree to:</p>
          <ul>
              <li>Use the services only for lawful purposes and in compliance with all applicable laws.</li>
              <li>Not use the services to submit or share any content that is illegal, harmful, or violates the rights of others.</li>
              <li>Ensure the accuracy of any information you provide and keep your login credentials secure.</li>
          </ul>

          <h3>4. Prohibited Activities</h3>
          <p>When using our services, you agree not to:</p>
          <ul>
              <li>Submit any content that is defamatory, obscene, or violates intellectual property rights.</li>
              <li>Attempt to interfere with the proper functioning of the website or services.</li>
              <li>Engage in any activity that could compromise the security of the service, including attempts to bypass or hack security measures.</li>
          </ul>

          <h3>5. Intellectual Property</h3>
          <p>All content, trademarks, logos, and intellectual property on the website are owned by or licensed to TubeGPT. You may not use, copy, or distribute any of these materials without prior written consent from us.</p>

          <h3>6. Termination of Use</h3>
          <p>We reserve the right to terminate or suspend your access to our services if you violate these Terms or engage in any activity that could harm us or other users.</p>

          <h3>7. Limitation of Liability</h3>
          <p>To the fullest extent permitted by law, TubeGPT will not be liable for any damages arising from your use of our services, including but not limited to direct, indirect, incidental, or consequential damages.</p>

          <h3>8. Changes to These Terms</h3>
          <p>We may update these Terms from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the site after such changes will constitute your acknowledgment and acceptance of the revised Terms.</p>

          <h3>9. Contact Us</h3>
          <p>If you have any questions or concerns regarding these Terms, please contact us at <a href="https://www.facebook.com/tubegpt/">our Facebook page</a>.</p>

          <p>By using our service, you agree to these Terms of Use.</p>
        </div>
        <Footer/>
      </div>
    );
};

export default TermsOfUse;
