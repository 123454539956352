import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Footer from '../components/Footer';
import Header from "../components/Header";
import {useUser} from "../contexts/UserContext";
import {initializeGoogleSignIn} from "../components/GoogleAuth";

function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptMarketing, setAcceptMarketing] = useState(false);
  const [errorMessage, setErrorMessage] = useState({}); // Changed to an object for multiple field errors
  const { user, setUser } = useUser();

  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!acceptTerms) {
      setErrorMessage({ terms: 'You must accept the terms of use to sign up.' });
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage({ password: 'Passwords do not match.' });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/register/`,
        {
          email,
          password,
          password2: confirmPassword,
          accept_marketing: acceptMarketing,
          accept_terms_of_use: acceptTerms,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        try{window.gtag('event', 'register', {method: 'form'});}catch(err){}
        setUser({
          email,
          access: response.data.access,
          refresh: response.data.refresh,
        });
        navigate('/chat');
      }
    } catch (error) {
      console.error('Error during signup:', error);

      try {
        if (error.response && error.response.data) {
          const errors = error.response.data;
          const formattedErrors = {};

          // Loop through error object and concatenate messages for each field
          Object.keys(errors).forEach((key) => {
            formattedErrors[key] = errors[key].join(' ');  // Join multiple errors for each field
          });

          setErrorMessage(formattedErrors);
          return;
        }
      } catch (error) {}
      setErrorMessage({ general: 'An error occurred. Please try again.' });
    }
  };

  const handleGoogleCredentialResponse = ({response}) => {
    const token = response.credential;

    axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/auth/google-login/`, { token })
      .then((res) => {
        try{window.gtag('event', 'register', {method: 'google'});}catch(err){}
        setUser({
          access: res.data.access,
          refresh: res.data.refresh,
          email: res.data.display,
        });
      })
      .catch((error) => {
        setErrorMessage('An error occurred. Please try again.');
      });
  };

  useEffect(() => {
      if (user) {
          navigate('/chat');
      }
    }, [user, navigate]);

  useEffect(() => {
    if (window.google) {
      initializeGoogleSignIn({handleGoogleCredentialResponse});
    } else {
      window.onload = () => {
        initializeGoogleSignIn({handleGoogleCredentialResponse})
      };
    }
  }, [setUser]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <div className="text-center mt-5 w-75" style={{maxWidth: '30rem'}}>
          <h2>Create your TubeGPT account</h2>
          <p>Start asking questions about YouTube videos.</p>

          {/* Displaying error messages */}
          {errorMessage.general && (
            <div className="alert alert-danger">
              {errorMessage.general}
            </div>
          )}
          {errorMessage.email && (
            <div className="alert alert-danger">
              <strong>Email: </strong>{errorMessage.email}
            </div>
          )}
          {errorMessage.password && (
            <div className="alert alert-danger">
              <strong>Password: </strong>{errorMessage.password}
            </div>
          )}
          {errorMessage.terms && (
            <div className="alert alert-danger">
              {errorMessage.terms}
            </div>
          )}

          {/* Signup form */}
          <form onSubmit={handleSignup} className="mt-4">
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group mb-3">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="form-group mb-3">
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            <div className="form-check mb-3 text-start">
              <input
                type="checkbox"
                className="form-check-input"
                id="acceptTerms"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="acceptTerms">
                I accept the <Link to="/terms-of-use" target="_blank">Terms of Use</Link>
              </label>
            </div>

            <div className="form-check mb-3 text-start">
              <input
                type="checkbox"
                className="form-check-input"
                id="acceptMarketing"
                checked={acceptMarketing}
                onChange={(e) => setAcceptMarketing(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="acceptMarketing">
                I agree to receive marketing emails
              </label>
            </div>

            <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
          </form>

          <p className="mt-3 text-secondary">or</p>

          {/* Google signup button */}
          <div id="googleSignInButton" className="mx-auto d-flex justify-content-center" style={{maxHeight: '40px'}}></div>


          {/* Login link */}
          <p className="mt-4">
            Already have an account? <Link to="/login">Sign in here</Link>.
          </p>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default RegisterPage;
