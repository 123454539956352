import React from 'react';
import { useUser } from "../contexts/UserContext";

function Header() {
  const currentPath = window.location.pathname;
  const { user, setUser } = useUser();

  return (
    <div className="offcanvas offcanvas-start" tabIndex="-1" id="global-drawer"
         aria-labelledby="offcanvasExampleLabel"
         style={{ maxWidth: '18rem' }}
    >
      <div className="offcanvas-header py-2 px-3">
        <span className="bi-list fs-2" data-bs-dismiss="offcanvas" aria-label="Close"></span>
      </div>

      <div className="offcanvas-body px-3">
        <a href="/" className="d-flex justify-content-center align-items-center mb-4 text-dark text-decoration-none">
          <img
            width={160}
            src="https://ucarecdn.com/54a4442b-6c20-409b-b6d5-4f1eb3c5496e/"
            alt="TubeGPT"
          />
        </a>

        <ul className="list-unstyled text-start">
          {/* Home Link */}
          <li className="py-3">
            <a
              className={`nav-link ${currentPath === '/' ? 'link-primary fw-bolder' : 'link-secondary'}`}
              href="/"
            >
              <i className="bi-house-door me-2"></i> Home
            </a>
          </li>

          {/* Chat Link */}
          <li className="py-3">
            <a
              className={`nav-link ${currentPath === '/chat' ? 'link-primary fw-bolder' : 'link-secondary'}`}
              href="/chat"
            >
              <i className="bi-chat-dots me-2"></i> Chat
            </a>
          </li>

          {/* Privacy Link */}
          <li className="py-3">
            <a
              className={`nav-link ${currentPath === '/privacy-policy' ? 'link-primary fw-bolder' : 'link-secondary'}`}
              href="/privacy-policy"
            >
              <i className="bi-lock me-2"></i> Privacy
            </a>
          </li>

          {/* Terms Link */}
          <li className="py-3">
            <a
              className={`nav-link ${currentPath === '/terms-of-use' ? 'link-primary fw-bolder' : 'link-secondary'}`}
              href="/terms-of-use"
            >
              <i className="bi-file-earmark-text me-2"></i> Terms
            </a>
          </li>
        </ul>

        {user ? (
          <div className="mt-4 text-center">
            <p className="text-muted">{user.email}</p>
            <button
              className="btn btn-outline-danger"
              onClick={() => setUser(null)}
            >
              Sign Out
            </button>
          </div>
        ) : currentPath !== '/login' && (
          <div className="mt-4 text-center">
            <a
              href="/login"
              className="btn btn-outline-primary"
            >
              Sign In
            </a>
          </div>
        )}
      </div>
      {currentPath === '/chat' && (
        <div className="offcanvas-bottom text-center">
        <span style={{cursor: 'default'}}
              className="fst-italic text-secondary align-items-center justify-content-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
            Stand with Gaza &#127477;&#127480;
        </span>
        </div>
      )}
    </div>
  );
}

export default Header;
