import React from 'react';
import Footer from "./Footer";
import Header from "./Header";

const PrivacyPolicy = () => {
    return (
      <div className="">
        <Header/>
        <div className="w-75 mx-auto d-flex flex-column justify-content-center" style={{maxWidth: '50rem'}}>
          <h1 className="text-center my-4">Privacy Policy</h1>
          <p><strong>Effective Date:</strong> OCTOBER 19, 2024</p>

          <h3>1. Introduction</h3>
          <p>Welcome to TubeGPT (“we”, “our”, “us”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services, particularly in relation to allowing users to submit YouTube URLs, ask questions about these videos, store messages, and sign in via Google. By accessing or using the site, you agree to this Privacy Policy. If you do not agree, please do not use the site.</p>

          <h3>2. Information We Collect</h3>
          <p>We collect several types of information from and about users, including:</p>
          <ul>
              <li><strong>Personal Information:</strong> When you sign in to our service using Google, we collect your email address and basic profile information (such as your name).</li>
              <li><strong>Messages and Content:</strong> We collect and store any messages, questions, or content you submit via our platform. This includes the URLs of YouTube videos you provide and any questions you ask about these videos.</li>
              <li><strong>Technical Data:</strong> We collect certain technical data, such as IP address, browser type, operating system, and usage data, to improve our services and ensure they function correctly.</li>
          </ul>

          <h3>3. How We Use Your Information</h3>
          <p>We may use the information we collect for various purposes, including:</p>
          <ul>
              <li>To provide, operate, and maintain our services.</li>
              <li>To process and respond to user inquiries and messages.</li>
              <li>To personalize your experience by remembering your preferences and interactions.</li>
              <li>To enhance the functionality and quality of the service based on user interactions.</li>
              <li>To manage and improve security on the platform.</li>
          </ul>

          <h3>4. How We Use OpenAI API</h3>
          <p>We use OpenAI’s API to process questions about the videos you submit. Please note that:</p>
          <ul>
              <li><strong>Data Retention by OpenAI:</strong> OpenAI may store your submitted messages and content (including questions and video URLs) for up to 30 days to monitor for abuse and misuse. After this period, the data is deleted from their systems.</li>
          </ul>

          <h3>5. Information Sharing and Disclosure</h3>
          <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following cases:</p>
          <ul>
              <li><strong>With Service Providers:</strong> We may share your information with third-party service providers, such as OpenAI, who assist in operating and improving our services.</li>
              <li><strong>Legal Compliance:</strong> We may disclose your information if required by law, such as to comply with a legal obligation or protect our rights and property.</li>
          </ul>

          <h3>6. Data Security</h3>
          <p>We take reasonable steps to protect your personal information and messages from unauthorized access, disclosure, or destruction. However, no security measures are completely foolproof, and we cannot guarantee the absolute security of your data.</p>

          <h3>7. Data Retention</h3>
          <p>We retain your personal data for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce agreements. Messages and interactions on the platform are retained until you request deletion or as otherwise necessary for our operations.</p>

          <h3>8. Your Rights</h3>
          <p>You have the following rights regarding your personal information:</p>
          <ul>
              <li><strong>Access:</strong> You can request a copy of the personal information we hold about you.</li>
              <li><strong>Correction:</strong> You can request corrections to any inaccurate or incomplete information.</li>
              <li><strong>Deletion:</strong> You may request that we delete your personal information and stored messages, subject to legal or operational obligations.</li>
              <li><strong>Withdrawal of Consent:</strong> You may withdraw your consent to our collection and processing of your data by discontinuing use of the service.</li>
          </ul>

          <h3>9. Sign-In via Google</h3>
          <p>We use Google as the sole authentication method for our platform. By signing in via Google, you are consenting to share your Google account’s basic profile information (such as your email address, and name) with us.</p>

          <h3>10. Changes to This Privacy Policy</h3>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the site after such changes will constitute your acknowledgment and acceptance of the changes.</p>

          <h3>11. Contact Us</h3>
          <p>If you have any questions about this Privacy Policy or our data practices, please contact us at <a href="https://www.facebook.com/tubegpt/">our Facebook page</a> .</p>

          <p>By using our service, you agree to the terms of this Privacy Policy.</p>
        </div>
        <Footer/>
      </div>
    );
};

export default PrivacyPolicy;
