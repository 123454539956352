import React, { useState, useEffect } from 'react';
import '../ChatPage.css';
import Header from "../components/Header";
import { useUser } from "../contexts/UserContext";
import useApi from "../utils/useApi";

function isRTL(text) {
  const rtlChars = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlChars.test(text);
}

function ChatPage() {
  const [videoUrl, setVideoUrl] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rateLimitReached, setRateLimitReached] = useState(false);
  const { user } = useUser(null);
  const api = useApi();
  const MAX_WIDTH = 52;

  const messagesDivRef = React.useRef(null);

  useEffect(() => {
    if (messagesDivRef.current) {
      window.scroll({
        top: messagesDivRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [messagesDivRef, messages]);

  const handleSendMessage = async () => {
    const localUserMessage = userMessage;
    setUserMessage('');
    if (loading || videoUrl.length < 1 || localUserMessage.length < 1 || rateLimitReached) {
      return;
    }

    try { window.gtag('event', 'send_chat_message', { message: localUserMessage }); } catch (err) { }

    try {
      setLoading(true);
      setMessages((prev) => [
        ...prev,
        { role: 'user', content: localUserMessage },
      ]);

      const apiEndpoint = user
        ? `${process.env.REACT_APP_BACKEND_DOMAIN}/api/transcript-chat/`
        : `${process.env.REACT_APP_BACKEND_DOMAIN}/api/transcript-chat/anonymous/`;

      const response = await api.post(apiEndpoint, {
        video_url: videoUrl,
        user_message: localUserMessage,
      });

      setMessages((prev) => [
        ...prev,
        { role: 'system', content: response.data?.response || 'Oops! Something went wrong!' },
      ]);
    } catch (error) {
      if (!user && error.response?.status === 429) {
        setRateLimitReached(true);
        setMessages((prev) => [
          ...prev,
          { role: 'system', content: error.response?.data?.error || 'Oops! Something went wrong!' },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          { role: 'system', content: error.response?.data?.error || 'Oops! Something went wrong!' },
        ]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setRateLimitReached(false);
    }
  }, [user]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main className={`container my-3 flex-grow-1 d-flex flex-column ${messages.length === 0 && 'align-items-center justify-content-center'}`} ref={messagesDivRef} style={{maxWidth: `${MAX_WIDTH}rem`}}>

        {/* Display a fun introductory message if no messages have been sent */}
        {messages.length === 0 && (
          <div className="text-center">
            <h5 className="mb-3 text-muted">Welcome! 👋</h5>
            <p className="text-muted">Paste a YouTube link to start chatting about the video!</p>
            <p className="text-muted">Try asking, “What is it talking about?”</p>
          </div>
        )}

        {/* Render the messages if there are any */}
        {messages.map((msg, index) => (
          <div key={index}
               className={`py-3 px-2 d-flex text-white ${index === 0 && `mt-auto`} ${msg.role === 'user' ? 'flex-row-reverse' : ''}`}>
            <p style={{
              'maxWidth': '75%',
              direction: isRTL(msg.content) ? 'rtl' : 'ltr',
              textAlign: isRTL(msg.content) ? 'right' : 'left'
            }}
               className={`p-2 rounded-4 ${msg.role === 'user' ? 'bg-primary' : 'bg-secondary'}`}>{msg.content}</p>
          </div>
        ))}
      </main>

      <div style={{ height: '12rem' }}></div>
      <footer style={{ maxWidth: `${MAX_WIDTH}rem` }} className="shadow-lg rounded-top-4 py-4 fixed-bottom bg-white mx-auto z-1">
        <div className="container">
          <div style={{ minHeight: '1.5rem' }}>
            {loading ? (
              <div className={`spinner-border spinner-border-sm mx-2 text-muted`} role="status">
                <span className="sr-only"></span>
              </div>
            ) : null}
          </div>

          <input
            className="form-control w-100 mb-3"
            type="text"
            placeholder="https://youtu.be/2UyxRgapLxA"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            disabled={!user && rateLimitReached}
          />

          <hr />

          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="What is it talking about?"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyDown={(event) => {
                const disabled = loading || videoUrl.length < 1 || userMessage.length < 1 || (!user && rateLimitReached);
                if (disabled) {
                  return;
                }
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSendMessage();
                }
              }}
              disabled={!user && rateLimitReached}
            />
            <button disabled={loading || videoUrl.length < 1 || userMessage.length < 1 || (!user && rateLimitReached)}
                    className="btn btn-outline-primary" type="button" onClick={handleSendMessage}>Send
            </button>
          </div>

          <p className="text-muted text-center mb-0 mt-2 small">AI can make mistakes, double check information</p>

        </div>
      </footer>
    </div>
  );
}

export default ChatPage;