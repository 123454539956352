import React from 'react';
import Drawer from "./Drawer";


function Header() {
  return (
    <header className="border-bottom d-flex justify-content-between sticky-top bg-white">
      <div className="px-2 py-2">
        <span data-bs-toggle="offcanvas" data-bs-target="#global-drawer" className="bi-list fs-3"></span>
      </div>
      <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
        <img width={120} src="https://ucarecdn.com/54a4442b-6c20-409b-b6d5-4f1eb3c5496e/-/resize/120x32/" alt="TubeGPT"/>
      </a>

      <Drawer/>
    </header>
  );

}

export default Header;
