import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Footer from '../components/Footer';
import Header from "../components/Header";
import { useUser } from '../contexts/UserContext';
import { initializeGoogleSignIn } from "../components/GoogleAuth";

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/login/`,
        {
          email,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        try{window.gtag('event', 'login', {method: 'form'});}catch(err){}
        setUser({
          email,
          access: response.data.access,
          refresh: response.data.refresh,
        });

        navigate('/chat');
      }
    } catch (error) {
      console.error('Login error:', error);

      if (error.response && error.response.data) {
        setErrorMessage('Invalid email or password.');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  };

  const handleGoogleCredentialResponse = ({response}) => {
    const token = response.credential;

    axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/auth/google-login/`, { token })
      .then((res) => {
        try{window.gtag('event', 'login', {method: 'google'});}catch(err){}
        setUser({
          access: res.data.access,
          refresh: res.data.refresh,
          email: res.data.display,
        });
      })
      .catch((error) => {
        setErrorMessage('An error occurred. Please try again.');
      });
  };

  useEffect(() => {
    if (user) {
      navigate('/chat');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (window.google) {
      initializeGoogleSignIn({ handleGoogleCredentialResponse });
    } else {
      window.onload = () => {
        initializeGoogleSignIn({ handleGoogleCredentialResponse });
      };
    }
  }, [setUser]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <div className="text-center mt-5 w-75" style={{maxWidth: '30rem'}}>
          <h2>Welcome to TubeGPT</h2>
          <p>Login is required to ensure continuous and secure service.</p>

          {/* Displaying error messages */}
          {errorMessage && (
            <div className="alert alert-danger">
              {errorMessage}
            </div>
          )}

          {/* Login form */}
          <form onSubmit={handleLogin} className="mt-4">
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group mb-3">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary btn-block">
              Sign in
            </button>
          </form>

          <p className="mt-3 text-secondary">or</p>

          {/* Google login button */}
          <div id="googleSignInButton" className="mx-auto d-flex justify-content-center" style={{maxHeight: '40px'}}></div>

          {/* Sign up link */}
          <p className="mt-4">
            Don't have an account? <Link to="/register">Sign up here</Link>.
          </p>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default LoginPage;
